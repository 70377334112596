<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col order-md="1" cols="12" md="6">
        <v-card style="padding: 20%" class="elevation-6 mt-15">
          <v-col class="card-content">
            <form @submit.prevent="handleSubmit">
              <v-card-text class="">
                <h1 class="text-center text-default-color mb-4">
                  {{ $t('resetPasswordTextTranslation') }} <br />
                </h1>
                <h4>{{ $t('pleaseEnterYourPasswordBelowText') }}</h4>
              </v-card-text>

              <v-text-field
                v-model="password"
                :rules="passwordRules"
                :label="$t('newPassword')"
                outlined
                rounded
                color="blue"
                autocomplete="false"
                :type="passwordShow ? 'text' : 'password'"
                :placeholder="$t('passwordInputFieldPlaceHolder')"
                prepend-inner-icon="mdi-key"
                :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="passwordShow = !passwordShow"
              />

              <v-text-field
                v-model="confirm_password"
                :rules="[passwordRules, matchingPasswordsRule]"
                :label="$t('confirmNewPasswordText')"
                outlined
                rounded
                color="blue"
                autocomplete="false"
                :type="passwordShow ? 'text' : 'password'"
                :placeholder="$t('passwordInputFieldPlaceHolder')"
                prepend-inner-icon="mdi-key"
                :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="passwordShow = !passwordShow"
              />

              <v-btn
                type="submit"
                class="mt-10"
                style="height: 3.5em"
                color="#536878"
                rounded
                dark
                block
              >
                <strong>{{ $t('submitButtonText') }}</strong>
              </v-btn>

              <div class="mt-2 bold-underlined text-center">
                <router-link to="/forgot-password"> Login </router-link>
              </div>
            </form>
          </v-col>
        </v-card>
        <ActionConfirmDialog
          :dialog="showMessageConfirmDialog"
          :message="message"
          :status-code="statusCode"
          @close-dialog="closeMessageShowDialog"
        ></ActionConfirmDialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ActionConfirmDialog from '../components/ActionConfirmDialog.vue';
import axios from 'axios';
import config from '../config';

export default {
  name: 'ConfirmPassword',
  components: { ActionConfirmDialog },

  data() {
    return {
      checkbox: false,
      valid: false,
      passwordShow: false,
      password: '',
      confirm_password: '',
      passwordRules: [
        (v) => !!v || 'Password is required',
        (v) =>
          (v && v.length >= 6) || 'Password must be 6  characters or more!',
      ],
      token: this.$route.params.token,
      message: '',
      showMessageConfirmDialog: false,
      statusCode: 0,
      baseURL: config.BASE_URL,
    };
  },
  computed: {
    matchingPasswordsRule() {
      return () =>
        this.password === this.confirm_password || 'Password must match';
    },
  },

  beforeMount() {
    axios
      .create({
        baseURL: config.BASE_URL,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .get(`/mmauth/api/verify/${this.token}`)
      .then(() => {})
      .catch((error) => {
        this.message = `Your password reset token expired, click okay to make a new request`;
        this.showMessageConfirmDialog = true;
        this.statusCode = error.status;
      })
      .finally(() => {
        this.email = '';
      });
  },

  methods: {
    closeMessageShowDialog() {
      this.showMessageConfirmDialog = false;
      if (this.statusCode == 200) {
        this.$router.push({ name: 'login' });
      } else {
        this.$router.push('/forgot-password');
      }
    },

    async handleSubmit() {
      let data = {
        password: this.password,
        confirm_password: this.confirm_password,
        token: this.token,
      };

      axios
        .create({
          baseURL: config.BASE_URL,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .post('mmauth/api/password-reset-complete/', data)
        .then((res) => {
          this.message = res.data.message;
          this.showMessageConfirmDialog = true;
          this.statusCode = res.status;
        })
        .catch((error) => {
          this.message = error.response.data.message;
          this.showMessageConfirmDialog = true;
          this.statusCode = error.status;
          this.closeMessageShowDialog();
        })
        .finally(() => {
          this.login();
          this.email = '';
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
